<template>
	<div>
		<a-spin :spinning="false">
			<div class="mt20 ft20 ftw500 cl-main">插件中心</div>
			<div class="mt30">
				<div class="flex wrap" v-if="datas.length>0">
					<div class="ant-col ant-col-6" style="padding-left: 8px; padding-right: 8px;" v-for="(item,index) in datas">
						<div class="pt15">
							<div class="gutter-box">
								<div class="text-center"><i class="iconfont ft36" :class="item.ico" style="color: rgb(71, 114, 255);"></i></div>
								<div class="mt12 ft16 text-center ftw500">{{item.name}}</div>
								<div class="mt12 text-center"><a :href="item.url" class="ft14" style="color: rgb(71, 114, 255);">去设置</a></div>
							</div>
						</div>
					</div>
				</div>
				
				<div v-else class="mt20 flex center">
					<a-empty/>
				</div>
				
			</div>
		</a-spin>
	</div>
</template>

<script>
	import {listMixin} from '../../common/mixin/list.js';
	export default{
		mixins:[listMixin],
		data(){
			return{
				loading:false,
				datas:[
				/*	{ico: 'iconqiyeweixin',name: '企业微信雷达',url: '#/shop/radar'},
					{ico: 'iconyuqie',name: '瑜伽多门店',url: '#/shop/yoga'},*/
					{ico: 'icona-iconmeirongmeifa',name: '美业多门店',url: '#/shop/beautiful'}
				/*	{ico: 'iconqicheweixiu',name: '汽修多门店',url: '#/shop/auto'},
					{ico: 'iconchabei',name: '棋牌室多门店',url: '#/shop/teahouse'},*/
				],
				search:{
					status:1,
				}
			}
		},
		methods:{
			/*getLists(){
				console.log(process.env.NODE_ENV);	
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/admin/cloudInit',{
					status:this.search.status,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.siteInfo.plugin;
				}).catch(res=>{
					console.log(res);
				}).finally(()=>{
					this.loading=false;
				})
			},*/
			
			edit(index){
				this.$router.push('/application/edit?id='+this.datas[index].app_id);
			},
			
			
		},
	}
</script>

<style>
	
	@media screen and (max-width:1150px){
		.application-index-item{
			width: 99%;
			height: 120px;
			background: #FFFFFF;
			border-radius: 4px;
			border: 1px solid #EBEDF5;
			padding: 30px;
			position: relative;
			margin-right: 1%;
			margin-bottom: 1%;
		}
	}
	
	
	@media screen and (min-width:1151px) and (max-width:1400px){
		.application-index-item{
			width: 49%;
			height: 120px;
			background: #FFFFFF;
			border-radius: 4px;
			border: 1px solid #EBEDF5;
			padding: 30px;
			position: relative;
			margin-right: 1%;
			margin-bottom: 1%;
		}
	}
	
	
	@media screen and (min-width:1401px){
		.application-index-item{
			width: 32%;
			height: 120px;
			background: #FFFFFF;
			border-radius: 4px;
			border: 1px solid #EBEDF5;
			padding: 30px;
			position: relative;
			margin-right: 1%;
			margin-bottom: 1%;
		}
	}
	
	.application-index-item-logo{
		width: 60px;
		height: 60px;
		border-radius: 4px;
	}
	
	.application-index-item-tui{
		position: absolute;
		top:0px;
		left:0px;
		width: 50px;
		height: 50px;
	}
	.gutter-box {
		width: 100%;
		height: 180px;
		background: #fff;
		border-radius: 4px;
		padding: 20px;
	}
	.iconqiyeweixin:before {
		content: "\e616";
		background: url(../../assets/image/qiyeweixin.png);
		background-repeat: no-repeat;
	}
	.iconyuqie:before {
		content: "\e616";
		background: url(../../assets/image/yujia.png);
		background-repeat: no-repeat;
	}
	.icona-iconmeirongmeifa:before {
		content: "\e616";
		background: url(../../assets/image/meifa.png);
		background-repeat: no-repeat;
	}
	.iconqicheweixiu:before {
		content: "\e616";
		background: url(../../assets/image/qixiu.png);
		background-repeat: no-repeat;
	}
	.iconchabei:before {
		content: "\e616";
		background: url(../../assets/image/cha.png);
		background-repeat: no-repeat;
	}
</style>
